import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SoloImage from '../SoloImage'
import SoloImageHero from '../SoloImage/SoloImageHero'
import ConnectedHero from '../ConnectedHero'
const ProcessPageTemplate = ({title, content, contentComponent, bg_hero, solo_image, solo_image_1, solo_image_2 }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
    {/*   <ConnectedHero bg_hero={bg_hero} title={title} /> */}
    <section id="hero_img">
    <SoloImageHero imgName={bg_hero} title={title} />
    </section>
      {/*  <section className='hero is-primary is-bold is-medium'>
        <div className={'kenburns-bottom hero-body ' + bg_hero}>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title focus-in-contract-bck'>
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
            <h1 className='title focus-in-contract-bck'>
              {title}
            </h1>
              <PageContent className='content' content={content} />
              {/*  <FormattedMessage id="aboutCopy" /> */}
              <SoloImage imgName={solo_image} />
            </div>
          </div>

        </div>
      </section>


    </div>
  )
}

ProcessPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  bg_hero: PropTypes.string,
  solo_image: PropTypes.string,
}

export default ProcessPageTemplate
